@use "../config/" as *;
.waf-authordetail {
    padding-bottom: 0;
    @extend %mt-12;
    @extend %p-4;
    .title {
        @extend %d-none;
    }
    .author {
        &-wrap {
            @extend %p-4;
            @extend %neutral-100-bg-2;
            @extend %half-radius;
        }
        &-name {
            @extend %capitalize;
            @extend %font-20-pb;
            @extend %secondary-800;
        }
        &-designation {
            @extend %d-block;
            @extend %font-12-pr;
            @extend %neutral-800;
            @extend %pt-1;
            @extend %pb-4;
        }
    }
    .img-box {
        aspect-ratio: 1 / 1;
        width: 10rem;
        height: 10rem;
        @extend %rounded-radius;
        @extend %mt-16-neg;
        @extend %mb-4;
    }
    .description {
        &-title {
            @extend %capitalize;
            @extend %font-16-pb;
            @extend %neutral-800;
        }
        &-text {
            line-height: 1.8rem;
            @extend %font-12-pr;
            @extend %neutral-800;
            @extend %d-block;
            @extend %pt-2;
            @extend %pb-4;
        }
    }
    .social-link {
        &-anchor {
            gap: var(--space-1);
            @extend %flex-v-center;
        }
        &-icon {
            width: 2.5rem;
            height: 2.5rem;
            padding: 0.6rem;
            @extend %d-block;
            @extend %circle-radius;
            @extend %hidden;
            @extend %flex-c-c;
            @extend %primary-200-bg;
            @extend %secondary-800;
        }
        &-image {
            max-width: 100%;
            max-height: 100%;
        }
        &-wrapper {
            gap: var(--space-2);
            @extend %flex;
        }
        &-text {
            @extend %font-10-pm;
            @extend %neutral-900;
        }
    }
}
@include mq(col-md) {
    .waf-authordetail {
        margin-top: 0;
        padding-inline: 0;
        .description-text {
            font-size: 1.4rem;
        }
        .author {
            &-details {
                background: clr(pure-white-900);
                border-radius: var(--space-1);
            }
            &-wrap {
                display: flex;
                background-color: unset;
            }
            &-content {
                margin-left: var(--space-12-neg);
                padding-block: var(--space-6);
                padding-inline: var(--space-18) var(--space-2);
                background: clr(neutral-100, 2);
                border-radius: var(--space-1);
                width: 100%;
            }
            &-designation {
                font-size: 1.4rem;
            }
        }
        .img-box {
            margin-top: var(--space-6);
        }
    }
}