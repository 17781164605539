@use "./config/" as *;
@forward "./main-scss/";
@forward "./author/writer-details";
@forward "./author/writer-listing";
@forward "./listing/related-list";
@forward "./partials/pagination";
@forward "./listing/listing-common";
.waf-listing.related-list {
    padding-bottom: var(--space-1);
    .head-tab {
        @extend %d-none;
    }
    .head-wrap {
        .title {
            border-bottom: .1rem solid var(--neutral-50);
            @extend %w-100;
            @extend %pb-4;
        }
    }
}
.waf-component.waf-ad.waf-ad-rectangle {
    background-color: clr(neutral-100, 2);
    padding-block: var(--space-7) var(--space-6);
    margin-block: 0;
    @extend %px-4;
    @extend %w-100;
}
@include mq(col-md) {
    .waf-component.waf-ad.waf-ad-rectangle {
        background-color: clr(neutral-50);
    }
}