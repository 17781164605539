@use "../config/" as *;
.waf-component.waf-listing:not(.related-list) {
    @extend %p-4;
    .title {
        @extend %d-none;
    }
    .article {
        &-list {
            @include card-count(1, var(--space-4), true);
            @include listing-card(horizontal, 11rem);
            .article-content {
                padding: 0;
            }
            .article-wrap {
                gap: var(--space-4);
            }
        }
        &-item {
            border: .2rem solid clr(neutral-100, 2);
            @extend %p-4;
        }
        &-title {
            margin-top: 0;
            @extend %neutral-800;
            @extend %mb-2;
        }
        &-meta {
            margin-block: 0;
        }
    }
    .img-box {
        aspect-ratio: 4 / 3;
    }
    .meta-date {
        @extend %font-10-pm;
        @extend %neutral-500;
    }
    .pagination-wrap {
        margin-block: var(--space-4) 0;
    }
}
@include mq(col-md) {
    .waf-component.waf-listing:not(.related-list) {
        padding-inline: 0;
        .article {
            &-list {
                display: grid;
                grid-template-columns: repeat(3, minmax(0, 1fr));
                margin-bottom: var(--space-8);
                .article-wrap {
                    display: block;
                    flex-direction: unset;
                    gap: unset;
                }
                .article-thumbnail {
                    width: 100%;
                }
                .article-content {
                    padding: var(--space-4);
                    background: clr(pure-white-900);
                    width: 100%;
                    border-radius: 0 0 var(--space-1) var(--space-1);
                }
            }
            &-item {
                padding: 0;
                border: 0;
                margin-bottom: 0;
            }
            &-title {
                margin-bottom: var(--space-4);
            }
        }
        .pagination-result {
            padding-block: var(--space-4);
        }
    }
}